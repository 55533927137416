 
.Accordion_container{
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
margin-bottom: 20vh;
}
.Accordion_inner{
    width: 80%;
    padding: 7vh;
    box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.139);
}
.accordion-button {
    font-size: 0.7em !important;
    font-weight: 600;
    border-radius: 25px !important;
}
.accordion-body{
    text-align: start;
    
}
.accordion-item{
    margin-bottom: 4vh;
    border: 0.5px solid rgba(0, 0, 0, 0.203) !important;
    border-radius: 25px;
}
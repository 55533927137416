 
.WhyChooseUs_Container{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 15vh;
    /* justify-content: space-between; */
    margin-bottom: 20vh;
}
.WhyChooseUs_left{
    width: 50%;
    display: flex;  
    justify-content: center;
      align-items: center;
}
.WhyChooseUs_Right{
    width: 50%;
    text-align: left;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
}
.whyChooseUs_pic{
    /* margin-top: 16vh; */
    width: 80%;
    border-radius: 25px;
}
.WhyChooseUs_Right_container{
    width: 80%;
}
.heading_3{
    color: #0582ca;
    font-weight: 700;
    font-size: 4vh;
    margin-top: 5vh;
    margin-bottom: 4vh;
}
.heading_2{
    font-size: 8vh;
    font-weight: 700;
    color: #001d3d;
    margin-bottom: 4vh;
}
.para_{
    font-size: 2vh;
     color: #757f95;
     font-weight: 500;
}

@media only screen and (max-width: 950px) {
    .WhyChooseUs_Container{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 15vh;
        justify-content: center;
        margin-bottom: 20vh;
        align-items: center;
    }
    .WhyChooseUs_left{
        width: 80%;
        display: flex;  
        justify-content: center;
          align-items: center;
    }
    .WhyChooseUs_Right{
        width: 60%;
        text-align: left;
        /* display: flex; */
        /* justify-content: center; */
        /* align-items: center; */
    }
}
@media only screen and (max-width: 430px) {
    .WhyChooseUs_left{
        width: 100%;
        display: flex;  
        justify-content: center;
          align-items: center;
    }
    .WhyChooseUs_Right{
        width: 90%;
        text-align: left;
        /* display: flex; */
        /* justify-content: center; */
        /* align-items: center; */
    }
}
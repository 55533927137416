.HomeAboutUsSec_Countainer{
    display: flex;
    margin-top: 16vh;
    text-align:end;
    justify-content: space-around;
    /* margin-left: 20vh; */
    height: 100%;
    flex-direction: row;
}

.HomeAboutUsSec_left{
    /* border: 1px solid black; */
    height: 100%;
    width: 40%;
display: flex;
flex-direction: column;
 align-items: flex-end;
}
.AboutusSec_heading{
    color: #0582ca;
    font-weight: 700;
    font-size: 4vh;
    margin-top: 5vh;
}
.AboutusSec_heading_main{
    font-size: 8vh;
    font-weight: 700;
    color: #001d3d;
}
 
.AboutusSec_para{
    font-size: 2vh;
     color: #757f95;
     font-weight: 500;
}
.AboutusSec_list{
    list-style-type:none;
    font-size: 2vh;
    color: #757f95;
}
.ul_container{
    width: 42vh;
    margin-top: 4vh;
    margin-bottom: 4vh !important;
    text-align: start;
    position: relative;
     left: 0px; 
}
.AboutusSec_list_content{
    margin-top: 1vh;
}
.Done_Icon{
background-color: #00a6fb;
border-radius: 70px;
display: inline;
color: white;
 font-weight: 700;
}
.HomeAboutUsSec_right{
    /* border: 1px solid ; */
    width: 45%;
    /* margin-left: 8vh; */
    position: relative;
   
}
.HomeAboutUsSec_right_frame{
    width: 80%;
    height:  75%;
    background-color: #00a6fb;
    z-index: -1;
    background-position: center;
    clip-path: polygon(0 0, 0 100%, 100% 0);
    border-top-left-radius: 25px;
    
}
.Dot_icon{
    z-index: -2;
    position: absolute;
    right: 0px;
    filter: invert(70%); 
    /* right: -2vh; */
   bottom: -6vh;
}
.AboutUsSec_Pic{
    position:absolute;
      top:5vh;
      left:5vh;
     
    height: 75vh;
    width: 85%;
    z-index: 1;
    border-radius: 22px;
}
@media only screen and (max-width: 1300px) {
    .HomeAboutUsSec_Countainer{
        display: flex;
        margin-top: 16vh;
        margin-bottom: 16vh;
        text-align:end;
        /* justify-content: center; */
        /* margin-left: 20vh; */
        flex-direction: column;
        /* border: 1px solid; */
       justify-content: center;
       margin-left: 5rem;
       margin-right: 5rem;
    }
    .HomeAboutUsSec_left{
        /* border: 1px solid black; */
        width: 100%;
       display: flex;
       flex-direction: column;
        align-items: flex-end;
        height: 90vh;
    }
    .HomeAboutUsSec_right{
        /* border: 1px solid ; */
        width: 100%;
        position: relative;
        height: 90vh;
         
    }
}
@media only screen and (max-width: 700px) {
    .HomeAboutUsSec_Countainer{
        display: flex;
        margin-top: 16vh;
        margin-bottom: 16vh;
        text-align:end;
        /* justify-content: center; */
        /* margin-left: 20vh; */
        flex-direction: column;
        /* border: 1px solid; */
       justify-content: center;
       margin-left: 0.5rem;
       margin-right: 0.5rem;
    }
    .HomeAboutUsSec_left{
        /* border: 1px solid black; */
        width: 100%;
       display: flex;
       flex-direction: column;
        align-items: flex-end;
        height: 120vh;
    }
    .HomeAboutUsSec_right{
        /* border: 1px solid ; */
        width: 100%;
        position: relative;
        height: 100vh;
         
    }
}
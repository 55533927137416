.PageHeadSec_Conatiner{
    width:100% ;
    position: relative;
    margin-bottom: 70vh;
}
.PageBg_pic{
    width: 100%;
    clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0 0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 50vh;
    filter: brightness(45%);
}
.PageBg{
    background-color: #ffd60a;
    height:24vh;
    width: 100%;
    position: absolute;
    z-index: -2;
    top: 33vh;
    clip-path: polygon(100% 0, 100% 22%, 50% 100%, 0 22%, 0 0);
}
.PageBg_Heading{
    color: white;
    font-size: 4.5em;
    position: relative;
    top: 2em;
}
.PageBg_Navigator{
    color: white;
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 7em;
}
.PageBg_Navigator_Home{
    text-decoration: none;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
}
.PageBg_Navigator_main{
    color: #ffd60a;
    margin-left: 10px;
    cursor: pointer;
}
.Testimonials{
    padding-top: 10vh;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Testimonial_heading{
    color: #ffc300;
    margin-bottom: 3vh;
}
.testmonials_flag{
    position: relative;
    top: -3vh;
    margin-bottom: 0vh
}
.Testimonial_IconPic{
    font-size: 3vh !important;
    color: #f6f6f6;
    align-self: center;
    height: 10vh;
    border-radius: 15vh;
}
.Testimonial_IconPic_container{
    background-color: #ffb703;
    height: 10vh;
    width: 10vh;
    border-radius: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    /* border: #f6f6f6 dotted; */
}
.TestimonialSec_Icon{
    position: absolute;
    top: -2vh;
    right: 2vh;
    background-color:white;
    height: 11vh;
    width: 11vh;
    display: flex;
    align-items: center;
    border: #ffc300 dotted;
 justify-content: center;
 border-radius: 70px;
 transition: all 0.5s ease-in-out;
}
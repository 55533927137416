.WhatWeOfferSec {
    margin-top: 20vh;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;
  
}

.card_Container {
    width: 80%;
    margin-bottom: 20vh;
}

.WhatWeOfferSec_heading {
    margin-top: 12vh;
    font-size: 5rem;
    font-weight: 700;
    color: #14213d;
    margin-bottom: 3vh;
}
.WhatWeOfferSec_heading2 {
    /* margin-top: 12vh; */
    font-size: 3rem;
    font-weight: 700;
    color: #14213d;
    margin-bottom: 10vh;
}

.card-text-sess-deco {
    margin-top: 4vh;
    font-size: 2vh;
    color: #757f95;
    user-select: none;
}

.card {
    background-color: #fff !important;
    border: none !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 15px 0 rgba(0, 0, 0, 0.19);
    text-align: start;
    padding: 2vh;
    transition: all 0.5s ease-in-out;
}
.card:hover{
    .WhatWeOfferSec_Icon{
        transform: rotate(360deg);
    }
    .TestimonialSec_Icon{transform: rotate(360deg);
         }
         
    .card-title {
            color: #ffb703;
        }

}

.card-title {
    margin-top: 4vh;
    font-size: 3.5vh;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    user-select: none;
}


.card-text-deco{
    color: #757f95;
    /* font-weight: 600; */
    margin-top: 1.5em;
    user-select: none;
    font-size: 1.2em;
}

.WhatWeOffer_btn{
    font-size: 1.2em;
    background: none;
    border: none;
    font-weight: 600;
    color: #ffb703;
    transition: all 0.3s ease-in-out;
    margin-bottom: 1vh;
}
.WhatWeOffer_btn:hover{
    color: black;
}
.WhatWeOfferSec_Icon{
    position: absolute;
    top: -3vh;
    right: 2vh;
    background-color:white;
    height: 12vh;
    width: 12vh;
    display: flex;
    align-items: center;
 justify-content: center;
 border-radius: 50px;
 transition: all 0.5s ease-in-out;
}
.WhatWeOfferSec_Icon_container{
  background-color: #ffb703;
  height: 10vh;
  width: 10vh;
  border-radius: 50px;
}
.WhatWeOfferSec_Icon_pic{
    font-size: 10vh !important;
    color: #f6f6f6;
   
}

@media only screen and (max-width: 1300px) {
    .card_Container {
        width:80%;
    }
}
.Footer_Container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #1f242e;
    padding-top: 8vh;
    flex-shrink: inherit;
    padding-bottom: 12vh;
}
.Footer_address{
    width: 25%;
}
.Footer_Link{
    width: 25%;
}
.Footer_ContactUs{
    width: 25%;
}
.Footer_logo{
    width: 25rem;
    filter:invert(100%) ;
}
.Footer_addressContent{
    color: white;
   margin-top: 2vh;
   font-size: 1.5rem;
}
.Footer_Link_heading{
    color: white;
    font-size: 3rem;
    font-weight: 600;
 text-align: start;
 margin-bottom: 4vh;
}
.Footer_link_list{
    list-style: none;
    text-align: start;
}
.Footer_arrow{
    color: #ffc300;
    font-size: 1.5rem;
}
.Footer_link_deco{
    text-decoration: none;
    color: white;
    font-size: 1.5rem
}
.Footer_ContactUs_heading{
    color: white;
    font-size: 3rem;
    font-weight: 600;
 text-align: start;
 margin-bottom: 4vh;
}
@media only screen and (max-width: 1050px) {
    .Footer_Container{
         flex-direction: column;
         align-items: center;
    }
    .Footer_address{
        width: 90%;
        margin-top: 2vh;
    }
    .Footer_Link{
        width: 90%;
        margin-top:4vh;
    }
    .Footer_ContactUs{
        width: 90%;
        margin-top:4vh;
        margin-bottom: 6vh;
       
    }
    .Footer_logo{
        width: 80%;
         
    }
    .Footer_link_deco{
        text-decoration: none;
        color: white;
        font-size: 1rem
    }
}
.Logo_img{
    height:12vh;
    width: 30vh;
   /* filter: invert(15%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(1000%) contrast(80%); */
}
.Nav_container{
    /* padding-left: 20vh;
    padding-right: 20vh; */
    background: transparent;
    backdrop-filter: blur(10px);
    background-color: #ffc40066;
    position: sticky;
    top: 0px;
    z-index: 2;
     
}
.navbar{
    background-color: rgba(0, 0, 0, 0.24) !important;
    background: transparent !important;
    backdrop-filter: blur(10px) !important;
    padding-left: 10vh !important;
    padding-right: 10vh !important;
}
.Nav_btn{
    font-size: 2.3vh;
    font-weight: 1000;
    margin: 2vh;
    color: black !important;
    transition: all 0.4s ease-in-out;
}
.Nav_btn:hover{
 color:white !important;
}
.Join_Btn{
    color: white;
    border: none;
    background-color: #003566;
    font-size: 2.3vh;
    font-weight: 1000;
    padding: 1vh;
    border-radius: 60px;
    display: flex;
   align-items: center;
   padding-left: 2vh;
    transition: all 0.5s ease-in-out;
}
.Join_Btn:hover{
    background-color: #3a90ec;  
    outline: none;
    border: none;
}
.Header_arrowbtn{
    background-color: white;
    border-radius: 50px;
    height: 5vh;
    width: 5vh;
    margin-left: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.Arrow_sign{
    color: #003566;
}
@media only screen and (max-width: 450px) {
    .navbar{
         padding-left: 0vh !important;
        padding-right: 0vh !important;  
    }
    .Logo_img{
        height:10vh;
        width: 20vh;}
}
/* Carosel SEC started ------------------------------------------*/


.carousel-caption{
  position: absolute;  
  top: 23vh;
  left: 20vh;
  text-align: start;
  animation: fadeIn 0.7s ease-in-out both;
}
.Carocel_content_H3{
 font-size: 3em;
}
.Carocel_content_Main{
    font-size: 5em;
    font-weight:700;
}
.Carocel_content_color{
    font-size: 5em;
    font-weight:700;
    color: #ffd60a;
}
.Carocel_content_para{
margin-top: em;
font-size: 3vh;
color: #ffd60a;
}
@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
.Carocel_btn_1{
    margin-top: 6vh;
    color: white;
    border: none;
    background-color: #003566;
    font-size: 2.3vh;
    font-weight: 1000;
    padding: 1vh;
    border-radius: 60px;
    display: flex;
   align-items: center;
   padding-left: 2vh;
    transition: all 0.5s ease-in-out;
}
.Carocel_btn_1:hover{
    background-color: #3a90ec;  
    outline: none;
    border: none;
}
.carousel-control-prev{
     width: 10vh;
}
.carousel-control-next{
   width: 10vh;
   
}
.carousel-control-next-icon{
    background-color: rgba(0, 0, 0, 0.276);
    width: 7vh;
    height:7vh;
    color: #003566 !important;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}
.carousel-control-next-icon:hover{
    background-color:#ffd60a ;
}
.carousel-control-prev-icon{
    background-color: rgba(0, 0, 0, 0.276);
    width: 7vh;
    height:7vh;
    color: #003566 !important;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}
.carousel-control-prev-icon:hover{
    background-color:#ffd60a ;
}
.Carosel_Btn_sec{
    display: flex;
}
.Carosel_Btn_Extra{
    margin-right: 2vh;
    background-color: #415a77;
}
@media only screen and (max-width: 1443px) {
    .carousel-caption{
        position: absolute;  
        top: 3rem;
        left: 7rem;
        text-align: start;
        animation: fadeIn 0.7s ease-in-out both;
      }
      .Carocel_content_H3{
        font-size: 1em;
       }
       .Carocel_content_Main{
           font-size: 4em;
           font-weight:700;
       }
       .Carocel_content_color{
           font-size: 4em;
           font-weight:700;
           color: #ffd60a;
       }
       .Carocel_content_para{
       margin-top: 2em;
       font-size: 3vh;
       color: #ffd60a;
       }
}
.SocialMedia_logo{
  height: 6vh;  
  width: 6vh;
  margin-bottom: 2vh;
}
.SocialMedia_logo_container{
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 5;
    top: 30vh;
    left:5px;
}
.w-100 {
    width: 100%!important;
    height: 120vh;
}
@media only screen and (max-width: 800px) {
    .carousel-caption{
        position: absolute;  
        top: 8rem;
        left: 7rem;
        text-align: start;
        animation: fadeIn 0.7s ease-in-out both;
      }
      .Carocel_content_H3{
        font-size: 0.8em;
       }
       .Carocel_content_Main{
           font-size: 2.5em;
           font-weight:700;
       }
       .Carocel_content_color{
           font-size: 2.5em;
           font-weight:700;
           color: #ffd60a;
       }
       .Carocel_content_para{
       margin-top: 2em;
       font-size: 1.2em;
       color: #ffd60a;
       }
}
@media only screen and (max-width: 400px) {
    .carousel-caption{
        position: absolute;  
        top: 8rem;
        left: 2rem;
        text-align: start;
        animation: fadeIn 0.7s ease-in-out both;
      }
      .Carocel_content_H3{
        font-size: 0.7em;
       }
       .Carocel_content_Main{
           font-size: 2.5em;
           font-weight:700;
       }
       .Carocel_content_color{
           font-size: 2.5em;
           font-weight:700;
           color: #ffd60a;
       }
       .Carocel_content_para{
       margin-top: 2em;
       font-size: 1em;
       color: #ffd60a;
       }
       .Logo_img{
        height:10vh;
        width: 25vh;
       /* filter: invert(15%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(1000%) contrast(80%); */
    }
    }
 
.ContactUsSec_Container{
display: flex;
justify-content:center ;
margin-bottom: 20vh;

}

.ContactUsSec_inner{
width: 74%;
/* border: 2px solid; */
box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.139);
border-radius: 20px;
display: flex;
padding-bottom: 10vh;
}
.SideSec_contactUsPage{
    padding: 4%;
  width:35%;
}
.Address_ContactUs{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 6vh;
}
.ContactUsIcons{
    font-size: 3.5vh;
}
.ContactUsIcons_outer{
    width: 8vh;
    height: 8vh;
    background-color: #ffd60a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px

}
.Address_ContactUs_heading{
    font-weight: 600;
}
.Address_ContactUs_para{
    color: #182333;
    font-weight: 600;
 
}
.contactUs_Content{
width: 30vh;
/* margin-top: 6vh; */
text-align: start;
margin-left:2vh 
}
.contactUsForm{
    width: 60%;
    margin-top:10vh;
    text-align: start;
}
.ContactUs_input{
width: 90%;
margin: 1.5vh;
border: 1px solid #ced4da;
padding: 2vh;
border-radius: 1vh;
color: #212529;
font-size: 2.5vh;
transition: all 0.5s ease-in-out;
}
.ContactUs_input:focus{
    border: 1px solid #ffd60a; 
    outline: #ffd60a;
    background-color: #ffd60a21;
}
.contactUs_Btn{
    color: white;
    background-color: #ffd60a;
    padding: 1.5vh 2vh;
    font-size: 1.5em;
    border: none;
    border-radius: 25px;
    margin-left:2vh ;
    transition: all 0.3s ease-in-out;
    border: 0.5px solid #ffd60a;
}
.contactUs_Btn:hover{
    color: white;
    background-color: #ffd50070;
    border: 0.5px solid #ffd60a;
}
@media only screen and (max-width: 1200px) {
    .ContactUsSec_inner{
        width: 74%;
        /* border: 2px solid; */
        box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.139);
        border-radius: 20px;
        display: flex;
        padding-bottom: 10vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        }
        .SideSec_contactUsPage{
            padding: 4%;
          width:100%;
        }
        .contactUsForm{
            width: 80%;
            margin-top:10vh;
            text-align: start;
             
        }
}

.SucessStories_Container{
    margin-top: 15vh;
    background-color: #ced4da35;
    display: flex;
    justify-content: center;
  

}
.SucessStories_inner{
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 15vh;
}
.SucessStories_heading{
    margin-bottom: 5vh;
    font-size: 5em;
    font-weight: 700;
    color: #001d3d;
}
.SucessStories_Img_Container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.SucessStories_Img_Frame{
    margin: 3%;
    height:50%;
    width: 38%;
    background-color: #f9cf002e;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3vh;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 15px 0 rgba(0, 0, 0, 0.19);
     
}
.SucessStories_Img{
    width: 80%;
    height:85%;
    border-radius: 2vh;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 15px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.5s ease-in-out
}
.SucessStories_Img:hover{
    height:95%;
}
